export default {
  axiosConfig: () => {
    return {
      baseURL: (window as any)._env_.REACT_APP_API_CLIENT,
      baseURL_CRM: (window as any)._env_.REACT_APP_API_CLIENT_CRM,
      timeout: 45000,
      responseType: 'json',
      auth: {
        client_id: 'medlink',
        client_secret: 'kidsecret'
      }
    }
  },
  version: "3.0.5"
}
